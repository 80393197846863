import * as React from "react"
import HeaderBar from "../components/HeaderBar"
import FooterBar from "../components/FooterBar"
import CurrentProject from "../components/CurrentProject"

const SwIRLPage = () => {
    return (
        <div>
            {HeaderBar()}
            <h1>Southwest Innovation Research Lab</h1>
            {CurrentProject()}
            <p><a href="http://southwestirl.org">SwIRL Website</a></p>
            <p>Southwest Innovation Research Lab (SwIRL) is a 501(c)(3) non-profit in College Station TX. As part of <a href="/sumobots">Aggie Robotics</a>, we helped to shape the non-profit, find use cases, ect. The process began in early 2020 and was sort of an ongoing, evolving vision. Eventually, SwIRL got its own facility and myself and a couple other students were brought on to help (part time) with the general tasks of a running a legal entity.</p>
            <p>The best description for my role is Chief Operating Officer at a non-profit startup. (Officially I am the <em>Lab Manager</em>.) I do a wide variety of tasks. Some days I'm overseeing painting, others I'm hanging cabinets, the next I'm training PhD students how to use the laser cutter, and the next I'm writing proposals for donations and grants. Supposedly, I am responsible for the machine shop: picking machines, setting them up, training people, and keeping things stocked and tidy. That is a big part of my job, but there are also a ton of other things that someone has to do, and that someone is usually me.</p>
            <p>It hectic: really the place functions more like a startup than an established business. We're figuring out a lot of things as we go (such as how to motivate or force people to clean up after themselves). I'll do a more proper writeup once things settle out a little bit because right now as soon as I explain what I do, I'm already doing something else.</p>
            {FooterBar()}
        </div>
    )
}

export default SwIRLPage
